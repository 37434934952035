.main {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
  flex-direction: row;
}
.left_column {
  flex: 50%;
  position: relative;
  background-color: #c32d8a;
}
.cover_image {
  width: 100%;
  height: 100vh;
  object-fit: contain;
}
.right_column {
  flex: 50%;
}
.right_column_content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8rem;
  padding-right: 8rem;
  background: var(--white);
}
.back_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3em;
}
.back_text {
  font-size: 0.8em;
  font-weight: 600;
  margin-left: 0.5em;
  color: var(--black);
}
.title {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0.2em;
  color: var(--black);
}
.sub_title {
  font-size: 0.8em;
  font-weight: 300;
  margin-bottom: 3em;
  color: var(--gray);
}
.form {
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.checkbox_container {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Primary-Gray-500);
}
.button_container {
  gap: 24px;
  display: flex;
  flex-direction: row;
  margin-top: 1em;
}
.separator {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
}
.separator_line {
  flex-grow: 1;
  border: 1px solid var(--gray-light);
}
.separator_text {
  margin-left: 0.5em;
  margin-right: 0.5em;
  color: var(--gray);
  font-size: 0.8rem;
}
.social_buttons {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.plans {
  display: grid;
  gap: 1em;
}
.plan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #36353667;
  border-radius: 8px;
  padding: 30px 20px;
  width: 100%;
}
.plan_details {
  width: 70%;
}
.plan_title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.plan_button {
  margin-left: 1rem;
}

.footer {
  margin-top: 3em;
  text-align: center;
  color: var(--gray);
  font-size: 0.8em;
}
.footer_text {
  color: var(--gray);
}
.footer_link {
  color: var(--primary);
  text-decoration: underline;
}
.forgot_password_link {
  color: var(--primary);
  text-decoration: underline;
  font-size: 0.8em;
}

@media screen and (min-width: 767px) and (max-width: 1000px) {
  .right_column {
    padding: 3em;
  }
}

@media screen and (max-width: 767px) {
  .right_column_content {
    padding: 0;
  }
  .left_column {
    display: none;
  }
  .right_column {
    padding: 2em;
  }
}

.privacy_link {
  text-decoration: underline;
  color: var(--Primary-ParadisePink-500);
  cursor: pointer;
}

.selected_domain {
  color: var(--Primary-ParadisePink-50);
  background-color: var(--Primary-ParadisePink-500);
  border: 1px solid var(--Primary-ParadisePink-500);
  &:hover {
    background: var(--Primary-ParadisePink-600);
  }
  &:active {
    background: var(--Primary-ParadisePink-700);
  }
}
.unselected_domain {
  color: var(--Primary-ParadisePink-800);
  background-color: var(--Primary-Gray-50);
  border: 1px solid var(--Primary-ParadisePink-800);
  &:hover {
    background: var(--Primary-Gray-100);
    box-shadow: inset 0 0 0 1px var(--Primary-ParadisePink-900);
  }
  &:active {
    background: var(--Primary-ParadisePink-100);
    box-shadow: inset 0 0 0 1px var(--Primary-ParadisePink-900);
  }
}
