.favorite-items {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 1rem;
  margin: 1.5rem 0;

  .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 32%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
    padding: 1.5rem;

    .image-container {
      position: relative;

      .discount {
        position: absolute;
        padding: 0.25rem 1.5rem;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: #ca2d5d;
        color: white;
        font-weight: bold;
      }

      .icon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        cursor: pointer;
        width: 45px;
      }

      .image {
        width: 100%;
        height: 210px;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    h2 {
      color: #ca2d5d;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
    .header {
      .price {
        border: 2px solid #ca2d5d;
        border-radius: 8px;
        padding: 0 12px;
        color: #ca2d5d;
        font-size: 16px;
        font-weight: 600;
      }
      span {
        color: #979797;
      }
    }

    .tutor span {
      font-weight: bold;
    }

    .location {
      display: flex;
      align-items: center;

      img {
        width: 25px;
        height: 26px;
        margin-right: 1rem;
      }
    }

    .domains {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      .domain {
        padding: 6px 12px;
        color: #ca2d5d;
        background-color: rgba(202, 45, 93, 0.15);
        border-radius: 20px;
      }
    }

    .rating {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 1440px) {
  .favorite-items {
    .item {
      width: 48%;
    }
  }
}

@media screen and (max-width: 768px) {
  .favorite-items {
    .item {
      width: 100%;
    }
  }
}
