.button {
  gap: 8px;
  display: flex;
  border: none;
  cursor: pointer;
  padding: 12px 34px;
  font-size: 14px;
  line-height: 26px;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: all 0.2s ease;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  height: 100%;
}

.primary_button {
  color: #ffffff;
  background: var(--Primary-ParadisePink-500);

  &:hover:not(:disabled) {
    box-shadow: inset 0 0 0 2px var(--Primary-ParadisePink-500);
    background: transparent;
    color: var(--Primary-ParadisePink-500);
  }
  &:active:not(:disabled) {
    background: var(--Primary-ParadisePink-100);
    box-shadow: inset 0 0 0 3px var(--Primary-ParadisePink-600);
    color: var(--Primary-ParadisePink-600);
  }
}

.secondary_button {
  color: var(--Primary-ParadisePink-600);
  background: var(--Primary-ParadisePink-100);

  &:hover:not(:disabled) {
    background: var(--Primary-ParadisePink-200);
  }
  &:active:not(:disabled) {
    background: var(--Primary-ParadisePink-300);
  }
}

.primary_dark_button {
  color: var(--Primary-ParadisePink-50);
  background-color: var(--Primary-ParadisePink-900);

  &:hover:not(:disabled) {
    background: var(--Primary-Gray-50);
    color: var(--Primary-ParadisePink-900);
    box-shadow: inset 0 0 0 2px var(--Primary-ParadisePink-900);
  }
  &:active:not(:disabled) {
    background: var(--Primary-Gray-100);
    color: var(--Primary-ParadisePink-900);
    box-shadow: inset 0 0 0 3px var(--Primary-ParadisePink-900);
  }
}

.tertiary_button {
  color: var(--Primary-Gray-850);
  background-color: var(--Primary-NavyBlue-50);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.outline_button {
  color: var(--Primary-ParadisePink-900);
  box-shadow: inset 0 0 0 1px var(--Primary-ParadisePink-900);

  &:hover:not(:disabled) {
    background: var(--Primary-Gray-100);
    box-shadow: inset 0 0 0 2px var(--Primary-ParadisePink-900);
  }
  &:active:not(:disabled) {
    background: var(--Primary-Gray-200);
    box-shadow: inset 0 0 0 3px var(--Primary-ParadisePink-900);
  }
}

.pale_button {
  color: var(--Primary-Gray-600);
  box-shadow: inset 0 0 0 1px var(--Primary-Gray-200);

  &:hover:not(:disabled) {
    background: var(--Primary-Gray-100);
    box-shadow: inset 0 0 0 2px var(--Primary-Gray-400);
  }
  &:active:not(:disabled) {
    background: var(--Primary-Gray-200);
    box-shadow: inset 0 0 0 3px var(--Primary-Gray-400);
  }
}

.wishlist_on_button {
  color: #ffffff;
  background: var(--Primary-ParadisePink-500);

  &:hover:not(:disabled) {
    background: var(--Primary-ParadisePink-600);
  }
  &:active:not(:disabled) {
    background: var(--Primary-ParadisePink-700);
  }
}

.wishlist_off_button {
  color: var(--Primary-ParadisePink-850);
  box-shadow: inset 0 0 0 1px var(--Primary-ParadisePink-850);

  &:hover:not(:disabled) {
    box-shadow: inset 0 0 0 2px var(--Primary-ParadisePink-500);
    background: var(--Primary-ParadisePink-100);
    color: var(--Primary-ParadisePink-600);
  }
  &:active:not(:disabled) {
    background: var(--Primary-ParadisePink-200);
    box-shadow: inset 0 0 0 3px var(--Primary-ParadisePink-500);
    color: var(--Primary-ParadisePink-600);
  }
}

.disabled_button {
  color: var(--gray);
  background-color: #f8fafc;
  cursor: not-allowed;
}

.wishlist_disabled {
  cursor: not-allowed;
}
