.instructor {
    gap: 20px;
    padding: 24px;
    height: 292px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #F8FAFC;
}
.instructor_picture {
    border-radius: 50%;
    object-fit: cover;
}
.instructor_name {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
}
.instructor_job {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #CA2D5D;
}
.instructor_description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #64748B;
}
.instructor_details {
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.instructor_social {
    gap: 16px;
    display: flex;
    flex-direction: row;
}