.footer {
  padding-top: 74px;
  padding-bottom: 2em;
  padding-left: 8.2%;
  padding-right: 8.2%;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  margin-top: 4em;
}
.footer_bg {
  width: 100%;
  width: 100%;
  background-color: var(--Primary-Purple-850);
  clip-path: polygon(0 0, 100% 54px, 100% 100%, 0% 100%);
}
.wave {
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: var(--Primary-ParadisePink-500);
  clip-path: polygon(0 0, 100% 81px, 100% 100%, 0% 100%);
}
@media (max-width: 1280px) {
  .footer {
    padding: 2em;
    padding-top: 94px;
  }
}
