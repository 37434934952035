.content {
  h2 {
    font-size: 1.25rem;
    padding-bottom: 1rem;
  }
}
.button_container {
  display: flex;
  gap: 1rem;
  width: 70%;
  margin-top: 1rem;
  margin-left: auto;
}
