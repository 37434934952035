.course_navbar {
    background-color: #FFFFFF;
}
.course_navbar_content {
    gap: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 7.5%;
    padding-right: 7.5%;
    margin: 0 auto;
}

@media screen and (max-width: 480px) {
    .course_navbar_content {
        flex-direction: column; 
        padding-left: 0;
        padding-right: 0;
        align-items: flex-start; 
    }

    .course_navbar_links {
        flex-direction: column; 
        gap: 0; 
    }

    .course_navbar_item {
        padding: 1em; 
        font-size: 0.875em; 
        text-align: left; 
        width: 100%; 
    }

    .course_navbar_item_active {
        text-align: left;
        width: 100%;
    }
}

.course_navbar_links {
    display: flex;
    flex-wrap: wrap;
}
.course_navbar_item {
    padding: 1.5em 1em;
    font-size: 1em;
    font-weight: 400;
    color: #64748B;
    cursor: pointer;
}
.course_navbar_item_active {
    color: #8755E0;
    border-bottom: 2px solid #8755E0;
}

@media screen and (max-width: 1280px) {
    .course_navbar_content {
        padding: 0 2em 2em 2em;
    }
}