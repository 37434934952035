.course_navbar {
  background-color: #ffffff;
}

@media screen and (max-width: 640px) {
  .course_navbar_item {
    padding: 1em;
    font-size: 0.875em;
    text-align: left;
    width: 33.33%;
    text-align: center;
  }
}

.course_navbar_links {
  display: flex;
  flex-wrap: wrap;
}
.course_navbar_item {
  position: relative;
  padding: 0 18px 12px 18px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border-bottom: 1px solid var(--Primary-ParadisePink-200);
}
/* .course_navbar_item_active {
  border-bottom: 3px solid var(--Primary-ParadisePink-500);
} */
.course_navbar_item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: var(--Primary-ParadisePink-500);
  transition: width 0.1s cubic-bezier(0.34, 0.32, 0.43, 0.9);
}
.course_navbar_item:hover::after,
.course_navbar_item_active::after {
  width: 100%;
}
