.video_container {
    background-color: #FFFFFF;
}
.video {
    padding: 2em;
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.video > h3 {
    font-size: 1.1rem;
    font-weight: 300;
    color: #7A3781;
    position: relative;
    bottom: -30px;
}
.react_player {
    border-radius: 20px;
    overflow: hidden;
}
.play_btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: -40px;
    width: 100px;
    height: 100px;
    background-color: #FFFFFF;
    border-radius: 50px;
}
.play_btn {
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: #7A3781;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}