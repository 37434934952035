.course_schedule {
  background-color: #ffffff;
}
.course_schedule_content {
  gap: 30px;
  padding-top: 2em;
  padding-bottom: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 7.5%;
  padding-right: 7.5%;
  margin: 0 auto;
  overflow-x: scroll;
}
@media screen and (max-width: 480px) {
  .course_schedule_content  {
    padding-left: 0;
    padding-right: 0;
  }
}
.course_schedule_title {
  font-size: 0.875rem;
  font-weight: 600;
  color: #8755e0;
  text-transform: uppercase;
}
.course_schedule_info {
  gap: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.course_schedule_details {
  flex: 2;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.course_schedule_details > h4 {
  font-size: 2em;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0.5em;
}
.course_schedule_details > h5 {
  font-size: 1.25em;
  font-weight: 700;
  color: #000000;
}
.course_schedule_details > p {
  font-size: 1em;
  font-weight: 400;
  color: #64748b;
}

.course_schedule_list {
  gap: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.course_schedule_list_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.course_schedule_list_item_icon {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #8755e0;
}
.course_schedule_list_item_text {
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.course_schedule_list_item_text > h6 {
  font-size: 1em;
  font-weight: 500;
  color: #000000;
}
.course_schedule_list_item_text > span {
  font-size: 0.875em;
  font-weight: 400;
  color: #64748b;
}

.btn_text {
  font-size: 0.75em;
  font-weight: 400;
  color: #64748b;
}

@media screen and (max-width: 1280px) {
  .course_schedule_content {
    padding: 2em;
  }
  .course_schedule_info {
    flex-direction: column;
  }
}
