.instructors_container {
  background-color: #ffffff;
}
.instructors {
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 8.2%;
  padding-right: 8.2%;
  margin: 0 auto;
  width: 100%;
  display: flex;
  gap: 50px;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.instructors_heading {
  display: flex;
  flex-direction: column;
  max-width: 768px;
}
.instructors_heading > span {
  font-size: 0.9em;
  font-weight: 600;
  color: #7a3781;
  text-transform: uppercase;
}
.instructors_heading > h2 {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 46px;
  color: #000000;
}
.instructors_heading > p {
  font-size: 1em;
  font-weight: 400;
  color: #64748b;
  margin-top: 20px;
}
.instructors_list {
  gap: 20px;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

@media screen and (min-width: 700px) and (max-width: 1200px) {
  .instructors {
    padding: 2em;
  }
}
@media screen and (max-width: 700px) {
  .instructors {
    padding: 2em;
  }
  .instructors_list {
    gap: 1em;
  }
}
