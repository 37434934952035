.react-international-phone-country-selector-button {
  padding: 1.4rem 0.8rem;
  font-size: 0.8rem;
  color: black;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 1px solid #cad4e0;
}

.react-international-phone-country-selector-button > * {
  border-radius: inherit !important;
}
