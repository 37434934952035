.course_stats {
  background-color: #f9f5ff;
}
.course_stats_content {
  gap: 120px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 8.2%;
  padding-right: 8.2%;
  margin: 0 auto;
  padding-top: 2em;
  padding-bottom: 2em;
}
.course_stats_item {
  gap: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.course_stats_item_content {
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.course_stats_item_value {
  font-size: 2.5em;
  font-weight: 600;
  color: #ca2d5d;
}
.course_stats_item_title {
  font-size: 1em;
  font-weight: 500;
  color: #000000;
}

@media screen and (max-width: 1280px) {
  .course_stats_content {
    padding: 2em;
  }
}
