.chapter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.chapter_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    padding: 1em;
    border: 0.5px solid #CAD4E0;
}
.chapter_header_title {
    flex: 1;
    font-size: 1em;
    font-weight: 400;
    color: #000000;
}
.chapter_header_details {
    font-size: 0.875em;
    font-weight: 400;
    color: #64748B;
}
.chapter_body {
    gap: 16px;
    padding: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #F9F5FF;
    border: 0.5px solid #CAD4E0;
}
.chapter_body_item {
    gap: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.chapter_body_item_title {
    flex: 1;
    font-size: 0.875em;
    font-weight: 500;
    color: #000000;
    text-align: justify;
}
.chapter_body_item_duration {
    font-size: 0.75em;
    font-weight: 400;
    color: #64748B;
}