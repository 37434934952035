.course_classrooms {
  background-color: #ffffff;
}
.course_classrooms_content {
  gap: 30px;
  padding-top: 2em;
  padding-bottom: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 7.5%;
  padding-right: 7.5%;
  margin: 0 auto;
}
.course_classrooms_title {
  font-size: 0.875rem;
  font-weight: 600;
  color: #8755e0;
  text-transform: uppercase;
}

.slick_container {
  width: 100%;
}
.slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;

  .classroom_image {
    width: 350px;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }

  .classroom_details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    h2 {
      font-size: 1.25rem;
      font-weight: 600;
    }
    .location {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #979797;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  color: #c70039;
}

.slick-dots li button:before {
  color: #c70039;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #c70039;
}

@media screen and (max-width: 1280px) {
  .course_classrooms {
    padding: 2em;
  }
}

@media screen and (max-width: 480px) {
  .course_classrooms_content {
    padding-left: 0.75em;
    padding-right: 0.75em;
  }

  .course_classrooms_title {
    font-size: 0.75rem;
  }

  .classroom_details h2 {
    font-size: 0.2rem;
  }
}

@media screen and (max-width: 480px) {
  .slick-slide .classroom_details h2 {
    font-size: 0.75rem !important;
  }
  .slick-slide .classroom_details .location span {
    font-size: 0.75rem; 
  }

  .slick-slide .classroom_details .location img {
    width: 16px; 
    height: 16px;
  }

}



