.colored_span {
    background: linear-gradient(
      90deg, 
      #7947D1 0%, 
      #FB4470 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold; 
  }
  
  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 494px;
    height: 708px;
    max-width: 100%; 
    max-height: 100%; 
    overflow: hidden; 
    padding: 40px 80px;
    border-radius: 12px 0 0 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background: white;
    z-index: 1000;
  }
/* 
  body {
    overflow: hidden;
  }
   */

  .modal-container {
    overflow-y: auto; 
  }
  
  