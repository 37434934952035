.questions_content_content {
  gap: 50px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
}
.questions_content_items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.colored_span {
  background: linear-gradient(
    90.64deg,
    #a449ff 0.55%,
    #f152b2 26.18%,
    #f84c8a 51.31%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.colored_span::selection {
  background: #ca2d5d;
  color: white;
  -webkit-text-fill-color: white;
  -webkit-background-clip: text;
}

@media screen and (max-width: 1024px) {
  .questions_content_content {
    padding: 0;
    gap: 20px;
  }
}

@media screen and (max-width: 640px) {
  .questions_content_content {
    gap: 16px;
  }
}
