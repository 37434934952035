.main {
  padding: 0rem 20rem;
}
/* nav {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
} */
.title {
  color: #808080b2;
  font-size: 2rem;
  font-weight: 500;
  padding: 1rem 0;
}

section {
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1rem 0;
  }
  .host-info {
    padding-top: 0;
  }
}

.inputs {
  display: flex;
  gap: 5rem;
  .right-column,
  .left-column {
    width: 50%;
  }
}
.field {
  padding: 0.5rem 0;
}
label {
  font-weight: 500;
}
.input-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  gap: 2rem;
  .upload-picture {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #cad4e0;
    border-radius: 8px;
    width: 100%;
    height: 200px;
    cursor: pointer;
    overflow: hidden;
    .default-pic {
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
  .fileInput {
    display: none;
  }
  .select {
    width: 100px - 2rem - 100px;
  }
  .input {
    width: 100%;
  }
  .bio {
    width: 100%;
    height: 200px;
    padding: 0.8rem;
    font-size: 0.8rem;
    border: 1px solid #cad4e0;
    border-radius: 8px;
  }
}
#files {
  display: block;

  .files {
    display: grid;

    .file {
      display: flex;
      margin: 0.5rem 0;
      align-items: center;
      background-color: hsl(0, 0%, 90%);
      border-radius: 8px;

      img {
        width: 50px;
      }
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .trash {
        display: none;
        width: 30px;
        /* height: 100%; */
        margin-right: 5px;
        margin-left: auto;
      }
    }
    .file:hover {
      .trash {
        display: block;
      }
      .trash:hover {
        cursor: pointer;
      }
    }
  }
}
.buttons {
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  gap: 2rem;
}

@media screen and (max-width: 1440px) {
  .main {
    padding: 0 5rem;
  }
}
@media screen and (max-width: 768px) {
  .main {
    padding: 0 2rem;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .right-column,
    .left-column {
      width: 100%;
    }
  }
}

.container {
  padding-left: 8.2%;
  padding-right: 8.2%;
  margin: 0 auto;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
}
