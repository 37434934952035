.faq {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.faq_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #CAD4E0;
}
.faq_header_number {
    font-size: 1em;
    font-weight: 700;
    color: #CA2D5D;
}
.faq_header_question {
    flex: 1;
    font-size: 1em;
    font-weight: 400;
    color: #000000;
}
.faq_body {
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #CAD4E0;
}
.faq_body > p {
    font-size: 1em;
    font-weight: 400;
    color: #000000;
    text-align: justify;
}