.gradient {
  background: linear-gradient(180deg, #fcf3ff 0%, rgba(255, 255, 255, 0) 100%);
  height: 645px;
  width: 100%;
  border-radius: 75px 75px 0 0;
  position: absolute;
  z-index: -1;
  left: 0;
}
.courses {
  gap: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2em;
}
.courses > .titles_container {
  gap: 12px;
  display: flex;
  flex-direction: column;
}
.titles_container > div > .sub_title {
  font-size: 0.875em;
  font-weight: 600;
  color: #8755e0;
  text-transform: uppercase;
}
.titles_container > div > .title {
  font-size: 2.5em;
  font-weight: 600;
  color: #000000;
}
.courses_list {
  position: relative;
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.load_more {
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  color: var(--Primary-ParadisePink-900);
  padding: 12px 34px;
  border-radius: 12px;
  border: 1px solid var(--Primary-ParadisePink-900);
}

@media screen and (max-width: 1280px) {
  .courses_list {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
}

@media screen and (max-width: 767px) {
  .courses_list {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

@media (max-width: 1024px) {
  .gradient {
    border-radius: 50px 50px 0 0;
  }
}

@media (max-width: 640px) {
  .gradient {
    border-radius: 30px 30px 0 0;
    height: 346px;
  }
  .courses {
    padding-top: 1em;
    gap: 6px;
  }
  .courses_list {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
