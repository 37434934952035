.rating_text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #8755e0;
  display: flex;
  flex-direction: row;
}

.course_details_block {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
