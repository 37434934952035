.new-course,
.new-session {
  padding: 3rem 15rem;
  h1 {
    color: #72849a;
    padding-bottom: 1rem;
  }

  .input-field {
    .bio {
      height: 120px;
    }
  }
}

.switch-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.objectives {
  display: flex;
  gap: 5rem;

  .left-column {
    width: 70%;
  }
  .right-column {
    width: 30%;
  }
}

.session-popup {
  content {
    top: 50%;
    left: 50%;
    width: 80%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    border-radius: 8px;
    border: 1px solid #ccc;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 1440px) {
  .new-course,
  .new-session {
    padding: 3rem 10rem;
  }
}
@media screen and (max-width: 1024px) {
  .new-course,
  .new-session {
    padding: 3rem 5rem;
  }
}
@media screen and (max-width: 768px) {
  .new-course,
  .new-session {
    padding: 3rem 5rem;
  }
  .objectives {
    flex-direction: column;
    gap: 0;

    .right-column,
    .left-column {
      width: 100%;
    }
  }
}
@media screen and (max-width: 480px) {
  .new-course,
  .new-session {
    padding: 3rem 2rem;
  }
  .objectives {
    flex-direction: column;
    gap: 0;

    .right-column,
    .left-column {
      width: 100%;
    }
  }
}
