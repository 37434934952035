.course_content {
    background-color: #FFFFFF;
}
.course_content_content {
    gap: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 7.5%;
    padding-right: 7.5%;
    margin: 0 auto;
    padding-top: 3em;
    padding-bottom: 3em;
}
.course_content_info {
    gap: 16px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.course_content_subtitle {
    font-size: 0.875em;
    font-weight: 600;
    color: #8755E0;
    text-transform: uppercase;
}
.course_content_title {
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
    color: #000000;
}
.course_summary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .numbers {
        display: flex;
        flex-wrap: wrap;
        color: #807A82;
    }

    .expand_all {
        font-size: 16px;
        font-weight: 700;
        color: #8755E0;
        cursor: pointer;
    }
}
.course_content_items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media screen and (max-width: 1280px) {
    .course_content_content {
        padding: 2em;
    }
}
@media screen and (max-width: 768px) {
    .course_summary {
        flex-direction: column;
        align-items: flex-start;
        gap: 1em;
    }
}
@media screen and (max-width: 768px) {
    .course_content_content {
      padding-left: 2em;
      padding-right: 2em;
    }
  }
  