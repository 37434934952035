.creatable {
  width: 95%;
}
.secondary_button {
  color: #ca2d5d;
  background-color: #ffffff;
  border: 1px solid #ca2d5d;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;
}
