.content {
    display: flex;
}
.title {
    font-size: 20px;
}
.text {
    font-size: 15px;
}
.button_container {
    display: flex;
    gap: 1rem;
    width: 70%;
    margin-top: 1rem;
    margin-left: auto;
}
.icon {
    width: 20px;
    margin: 7px 15px 0 0;
}
.error {
  color: #bb003b;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}