.topics_container {
  background-color: #ffffff;
}
.topics {
  width: 100%;
  display: flex;
  gap: 26px;
  flex-direction: column;
  padding: 20px 0;
}
.topics_heading {
  gap: 6px;
  display: flex;
  flex-direction: column;
}
.topics_heading > h2 {
  width: fit-content;
  background: linear-gradient(
    91.54deg,
    #8b49ff -6.31%,
    #e64dff 48.72%,
    #e83c70 100.55%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.topics_heading > h2::selection {
  background: #ca2d5d;
  color: white;
  -webkit-text-fill-color: white;
  -webkit-background-clip: text;
}
.topics_list {
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.topic {
  gap: 16px;
  padding: 26px;
  display: flex;
  flex-direction: column;
  text-align: flex-start;
  background-color: var(--Primary-Gray-50);
  border-radius: 8px;
  box-shadow: 0px 18px 26px -4px #00000005;
}
.topic_icon {
  padding: 6px;
  border-radius: 7px;
  background-color: var(--Primary-ParadisePink-50);
}
.topic_footer {
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 1em;
  font-weight: 500;
  color: #7a3781;
}

@media (max-width: 1024px) {
  .topics_list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 640px) {
  .topics_list {
    grid-template-columns: repeat(1, 1fr);
  }
}
