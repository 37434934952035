.course_details_block {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.date-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  display: inline-block; 
}

@media (max-width: 640px) {
  .date-container {
    font-size: 12px; 
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-caption-text {
  flex-shrink: 1; 
  max-width: calc(100% - 2rem); 
}


.rating_text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #8755e0;
  display: flex;
  flex-direction: row;
}

.selected_domain {
  color: var(--Primary-ParadisePink-50);
  background-color: var(--Primary-ParadisePink-500);
  border: 1px solid var(--Primary-ParadisePink-500);
  &:hover {
    background: var(--Primary-ParadisePink-600);
  }
  &:active {
    background: var(--Primary-ParadisePink-700);
  }
}
.unselected_domain {
  color: var(--Primary-ParadisePink-800);
  background-color: var(--Primary-Gray-50);
  border: 1px solid var(--Primary-ParadisePink-800);
  &:hover {
    background: var(--Primary-Gray-100);
    box-shadow: inset 0 0 0 1px var(--Primary-ParadisePink-900);
  }
  &:active {
    background: var(--Primary-ParadisePink-100);
    box-shadow: inset 0 0 0 1px var(--Primary-ParadisePink-900);
  }
}

.custom_scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom_scrollbar::-webkit-scrollbar-track {
  background-color: #dfdfdf;
  border-radius: 4px;
}

.custom_scrollbar::-webkit-scrollbar-thumb {
  background-color: #6d6d6d;
  border-radius: 4px;
}
