.course_faqs {
    background-color: #FFFFFF;
    flex-direction: column;
}
.course_faqs_content {
    gap: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 7.5%;
    padding-right: 7.5%;
    margin: 0 auto;
    padding-top: 3em;
    padding-bottom: 3em;
    
}
.course_faqs_info {
    gap: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.course_faqs_subtitle {
    font-size: 0.875em;
    font-weight: 600;
    color: #8755E0;
    text-transform: uppercase;
}
.course_faqs_title {
    max-width: 600px;
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
    color: #000000;
}
.course_faqs_items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media screen and (max-width: 1280px) {
    .course_faqs_content {
        padding: 2em;
    }
}