.container {
    padding: 3rem 15rem;
}

h1 {
    font-size: 1.5rem;
}

.profile {
    display: flex;
    gap: 2rem;
    align-items: center;
    padding: 3rem 0;

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    .name {
        color: #72849a;
        font-size: 1.5rem;
    }
}

.tabs {
    display: flex;
    gap: 2rem;

    .tab {
        color: #72849a;
        padding-bottom: .5rem;
        font-weight: 500;
    }
    .tab:hover {
        color: #f50057;
        cursor: pointer;
    }
    .active {
        color: #f50057;
        border-bottom: 2px solid #f50057;
    }
}

.tab-content {
    padding: 2rem 0;

    .personal-info {
        .bio {
            height: 100px;
        }
        .upload-picture {
            width: 5rem;
            height: 5rem;
        }
    }

    input {
        padding: 0.8rem;
        font-size: 0.8rem;
        color: var(--black);
        border: 1px solid #cad4e0;
        border-radius: 8px;
        width: 100%;
      }
}

@media screen and (max-width: 1440px) {
    .container {
        padding: 3rem 10rem;
    }
    .profile img {
        width: 80px;
        height: 80px;
    }
}
@media screen and (max-width: 1024px) {
    .container {
        padding: 3rem 5rem;
    }
    .profile img {
        width: 80px;
        height: 80px;
    }
}
@media screen and (max-width: 768px) {
    .container {
        padding: 3rem 5rem;
    }
    .profile {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }
    .tabs {
        flex-direction: column;
        gap: 1rem;
    }
}
@media screen and (max-width: 480px) {
    .container {
        padding: 3rem 2rem;
    }
    .profile {
        img {
            width: 80px;
            height: 80px;
        }
        .name {
            font-size: 1.2rem;
        }
    }
    .tabs {
        .tab {
            font-size: .8rem;
        }
    }
}