.payment_form {
  flex: 1;
  gap: 1em;
  display: flex;
  flex-direction: column;
}
.payment_form_container {
  gap: 1em;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #cad4e0;
  border-radius: 8px;
}
.payment_form_row {
  gap: 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.checkbox_container {
  gap: 5px;
  display: flex;
  font-size: 0.8em;
  color: #000000;
  align-items: center;
  text-align: left;
}
.add_friend {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.add_friend > h4 {
  font-size: 1em;
  font-weight: 600;
  color: #000000;
}

.payment_progress {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.progress_item {
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.progress_item_count {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #cad4e0;
}
.progress_item_count_active {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  color: #ffffff;
  background-color: #ca2d5d;
}
.payment_progress > hr {
  flex-grow: 1;
  border: 1px solid #cad4e0;
}
.progress_item_text {
  font-size: 0.9em;
  font-weight: 400;
  color: #000000;
}

.payment_methods {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.payment_methods > h4 {
  font-size: 1em;
  font-weight: 600;
  color: #000000;
  text-align: left;
}
.payment_methods_container {
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.payment_method_name {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.payment_method {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-size: 0.9em;
  font-weight: 400;
  color: #000000;
  border-radius: 8px;
  border: 1px solid #cad4e0;
  width: 100%;
}
.payment_methods_security {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.payment_methods_security > span {
  font-size: 0.9em;
  font-weight: 400;
  color: #64748b;
}

@media screen and (max-width: 1000px) {
  .payment_form {
    width: 100%;
  }
}

.paymentMethods {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}
.title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
}
.paymentOptions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.paymentOption {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}
.paymentOption input {
  margin-right: 10px;
}
.paymentOption.selected {
  border: 2px solid #00b4d8;
  background: #e0f7fa;
}
.cardPaymentContainer {
  margin-top: 20px;
}
.cardElement {
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  margin-bottom: 15px;
}
.payButton {
  padding: 12px 20px;
  background: #00b4d8;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}
.payButton:disabled {
  background: #90e0ef;
}
.errorMessage {
  color: red;
  margin-top: 10px;
}
.securityMessage {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.securityMessage img {
  margin-right: 10px;
}
.securityMessage span {
  font-size: 0.9rem;
  color: #555;
}