.steps {
  padding-top: 2em;
  padding-bottom: 2em;
  width: 100%;
  display: flex;
  gap: 50px;
  text-align: center;
  flex-direction: column;
}
.steps > h2 {
  font-size: 1.3em;
  font-weight: 600;
  line-height: 46px;
  color: #000000;
}
.steps_list {
  display: flex;
  flex-direction: row;
}
.step {
  flex: 1;
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.step > .icon_container {
  width: 110px;
  height: 110px;
  border-radius: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step > .text_container {
  gap: 16px;
  display: flex;
  text-align: center;
  flex-direction: column;
}
.step > .text_container {
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.step > .text_container > .title {
  font-size: 1em;
  font-weight: 600;
  color: #000000;
  line-height: 36px;
}
.step > .text_container > .subTitle {
  font-size: 0.9em;
  font-weight: 400;
  color: #64748b;
  line-height: 28px;
}

@media (max-width: 1280px) {
  .steps {
    padding: 50px 0;
  }
}
@media screen and (max-width: 640px) {
  .steps {
    padding: 30px 0 0 0;
  }
  .steps_list {
    gap: 24px;
    flex-direction: column;
  }
}
