.categories {
    background-color: #F9F5FF;
}
.categories_content {
    gap: 32px;
    align-items: center;
    padding-top: 4em;
    padding-bottom: 4em;
    margin: 0 auto;
    max-width: 896px;
    display: flex;
    flex-direction: column;
}
.categories_heading {
    gap: 8px;
    max-width: 548px;
    display: flex;
    flex-direction: column;
}
.categories_title {
    font-size: 1.5em;
    font-weight: 700;
    color: #000000;
    text-align: center;
}
.categories_subtitle {
    font-size: 1em;
    font-weight: 400;
    color: #64748B;
    text-align: center;
}
.categories_list {
    gap: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.categories_item {
    padding: 1em;
    color: #8755E0;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #8755E0;
    cursor: pointer;
}
.categories_item:hover {
    color: #FFFFFF;
    background-color: #8755E0;
}
.categories_item > h3 {
    font-size: 1em;
    font-weight: 500;
}

@media screen and (max-width: 896px) {
    .categories_content {
        padding: 2em;
    }
}
