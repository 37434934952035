.container {
  padding-left: 7.4%;
  padding-right: 7.4%;
  margin: 0 auto;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 1280px) {
  .container {
    padding: 2em;
    gap: 2em;
  }
}
