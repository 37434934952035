.enrolled_container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.enrolled_container > main {
    flex: 1;
    gap: 32px;
    max-width: 600px;
    height: 100%;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.text_container {
    gap: 16px;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
}
.sub_title {
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.sub_title > h2 {
    font-size: 2em;
    font-weight: 700;
    color: #8755E0;
}
.titles_container {
    gap: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.titles_container > div > .title_1 {
    font-size: 4em;
    font-weight: 600;
    color: #000000;
}
.titles_container > div > .title_2 {
    font-size: 2.5em;
    font-weight: 600;
    color: #000000;
}
.text_container > p {
    font-size: 1em;
    font-weight: 600;
    color: #64748B;
}
.buttons_container {
    gap: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

@media screen and (max-width: 589px) {
    .enrolled_container > main {
        padding: 2em;
    }
    .sub_title > h2 {
        font-size: 1.1em;
    }
    .buttons_container {
        gap: 8px;
        flex-direction: column;
    }
}