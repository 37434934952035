.table-page-container {
    padding: 3rem 15rem;
}
.links {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    color: #72849a;
    padding-bottom: 3rem;

    .link {
        display: flex;
        gap: 0.5rem;
    }
    .active {
        color: #1a1a1a;
    }
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tab-content {
    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 .5rem ;

        h1 {
            font-size: 1.4rem;
            font-weight: bold;
            color: #252733;
        }
        .features {
            display: flex;
            gap: 2rem;
            color: #4B506D;

            .icon {
                display: flex;
                gap: 0.5rem;
                cursor: pointer;
            }
        }
    }

    .table-container {
        max-width: 100%;
        overflow-x: auto;
    }

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        margin: 1.5rem 0;
        border: 1px solid #DFE0EB;
        border-radius: 8px;
        
        th, td {
            /* height: 5.5rem; */
            padding: 1rem 2rem;
            vertical-align: middle;
        }
        tr th {
            color: #9FA2B4;
            text-align: start;
            font-size: 20px;
            font-weight: 400;
        }
        tr {
            position: relative;
        } 
        tbody td {
            height: 5.5rem;
            border-top: 1px solid #DFE0EB;
        }
        
        tbody tr:hover {
            background-color: #fafafa;
            button {
                background-color: #fafafa;
            }
        }
        .name {
            font-weight: bold;
        }
        .rating {
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: .25rem;

                img {
                    width: 22px;
                }
            }
        }
        #options {
            padding: 1.75rem;

            img {
                cursor: pointer;
            }
            .options {
                gap: 10px;
                top: 4rem;
                right: .5rem;
                padding: 1em;
                display: flex;
                flex-direction: column;
                position: absolute;
                font-size: 1em;
                font-weight: 500;
                color: #000000;
                border-radius: 8px;
                background-color: #fff;
                box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
                z-index: 100;
              
                .link:hover {
                    cursor: pointer;
                    color: #CA2D5D;
                }
            }
            .options > hr {
                height: 2px;
                background-color: #D4D3D3;
                box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
            }
        }

        #nodata:hover {
            background-color: #fff;
        }
    }  
    .nodata {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: rgba(0,0,0,.25);
        width: 100%;

        img {
            width: 50px;
        }
    }
    
    .pagination {
        display: flex;
        justify-content: flex-end;
        gap: 1.5rem;
        margin-right: 1.5rem;
        color: #9FA2B4;
    }
}

@media screen and (max-width: 1440px) {
    .table-page-container {
        padding: 3rem 10rem;
    }
}
@media screen and (max-width: 1024px) {
    .table-page-container {
        padding: 3rem 5rem;
    }
}
@media screen and (max-width: 768px) {
    .table-page-container {
        padding: 3rem 5rem;
    }
    .header {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }
    .tabs {
        flex-direction: column;
        gap: 1rem;
    }
}
@media screen and (max-width: 480px) {
    .table-page-container {
        padding: 3rem 2rem;
    }
    .tabs {
        flex-direction: row;
        .tab {
            font-size: .8rem;
        }
    }
}