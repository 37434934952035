.colored_title {
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  background: linear-gradient(
    90.48deg,
    #a449ff 6.33%,
    #f152b2 50.74%,
    #f84c8a 94.27%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 1024px) {
  .colored_title {
    font-size: 38px;
    font-weight: 700;
    line-height: 44px;
  }
}

@media screen and (max-width: 640px) {
  .colored_title {
    font-size: 22px;
    font-weight: 800;
    line-height: 32px;
  }
}
