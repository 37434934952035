.cohort_container {
  background-color: #ffffff;
  gap: 16px;
  padding-top: 2em;
  padding-bottom: 2em;
  /* display: flex; */
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8.2%;
  padding-right: 8.2%;
  margin: 0 auto;
  /* max-height: 900px; */
}
.cohort {
  gap: 32px;
  display: flex;
  flex-direction: row;
}
.cohort_list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 750px !important;
  


  img {
    width: 156px;
    height: 100px;
    object-fit: cover;
    border-radius: 16px;
  }
}
.cohort_info {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cohort_subtitle {
  font-size: 0.875em;
  font-weight: 600;
  color: #8755e0;
  /* text-transform: uppercase; */
}
.cohort_title {
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  color: #000000;
}
.cohort_item_image {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.selected_details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 1rem;
  max-height: 560px;
  

  .details_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .domains {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      font-size: 16px;
      font-weight: 400;
      color: #8755e0;
    }
    .rating {
      display: flex;
      align-items: center;
      gap: 5px;
      .rating_number {
        font-size: 16px;
        font-weight: 400;
        color: #8755e0;
      }
      .count {
        font-size: 16px;
        font-weight: 400;
        color: #969696;
      }
    }
  }
  h4 {
    font-size: 1.5em;
    font-weight: 600;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #64748b;
  }
  .items {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #afafaf;
    padding-top: 12px;

    .after {
      font-size: 26px;
      font-weight: 600;
      color: #8755e0;
    }
    .before {
      font-size: 20px;
      font-weight: 400;
      color: #ababab;
      text-decoration: line-through;
    }
  }
}
.cohort_item {
  gap: 8px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
}
.cohort_item_selected {
  border-left: 4px solid #ca2d5d;
}
.cohort_item_info {
  flex: 1;
  gap: 4px;
  display: flex;
  flex-direction: column;
}
.cohort_item_info > h4 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  text-align: justify;
}
.cohort_item_info > p {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  color: #64748b;
  /* text-align: justify; */
  /* height: 2.5em; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.cohort_details {
  flex: 2;
  height: 750px !important;
  gap: 16px;
  /* display: flex;
    flex-direction: column; */
  box-shadow: 0px 1px 6px 0px #2323231f;
  border-radius: 16px;
}
.react_player {
  overflow: hidden;
  border-radius: 8px;
}

@media screen and (max-width: 1280px) {
  .cohort {
    padding: 0 2em;
    flex-direction: column;
  }
  .cohort_list {
    flex-direction: row;
  }
  .cohort_item_selected {
    border-left: none;
    border-bottom: 4px solid var(--pink);
  }
  .cohort_item_info {
    display: none;
  }
  .cohort_info {
    padding: 0 2em;
  }
}

.selected_details p {
  font-size: 1rem;
  font-weight: 400;
  color: #64748b;
  text-align: justify; 
  word-wrap: break-word; 
  overflow: hidden; 
  max-width: 100%; 
  margin: 0 auto; 
}

@media screen and (max-width: 768px) {
  .selected_details p {
      font-size: 0.875rem; 
      line-height: 1.2; 
  }
  .selected_details h4 {
    font-size: 1rem; 
    line-height: 1.2; 
}
.selected_details span {
  font-size: 0.8rem; 
  line-height: 1.2; 
}

}

@media screen and (max-width: 768px) {
  .items {
    gap: 8px; 
    font-size: 0.875rem;
  }

  .items img {
    width: 16px; 
    height: 16px; 
  }

  .items span {
    font-size: 0.8rem;
  }
  
}



@media screen and (max-width: 768px) {
  .cohort_list {
    flex-direction: row;
    overflow-x: auto; 
    max-height: 150px; 
    gap: 8px; 

    img {
      width: 100px;
      height: 80px;
      margin-bottom: 3px;
    }
  }

  .cohort_item {
    flex-shrink: 0; 
    width: 100px;
    padding: 8px;
    border-bottom: 4px solid transparent; 
  }

  .cohort_item_selected {
    border-bottom: 4px solid #ca2d5d; 
  }

  .cohort_item_info {
    display: none; 
  }

  .cohort_info {
    padding: 0 1em; 
  }
}




