.navbar_container {
  z-index: 20;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.navbar_inner {
  max-width: 1440px;
  width: 100%;
  padding: 0 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar_links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  padding: 6px 10px;
}
.navbar_links > a {
  font-size: 14px;
  font-weight: 600;
  color: var(--Primary-ParadisePink-850);
  text-decoration: none;
  transition: all 0.1s ease;

  &:hover {
    color: var(--Primary-ParadisePink-600);
  }
}
.navbar_buttons {
  gap: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar_button {
  font-size: 14px;
  font-weight: 600;
  color: var(--Primary-ParadisePink-500);
  white-space: nowrap;
}
.navbar_mobile {
  display: none;
}
.navbar_mobile_links {
  gap: 10px;
  right: 2em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  position: absolute;
  font-size: 1em;
  font-weight: 500;
  color: #000000;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  z-index: 100;

  .username {
    color: #ca2d5d;
  }
}
.navbar_mobile_links > hr {
  height: 2px;
  background-color: #ca2d5d;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
}
.navbar_user {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.navbar_user_links {
  display: flex;
  flex-direction: column;
  position: absolute;
  font-size: 1em;
  font-weight: 500;
  color: #000000;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  z-index: 100;

  .link:hover {
    color: #ca2d5d;
  }
}
.navbar_user_links > hr {
  height: 2px;
  background-color: #ca2d5d;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
}
.navbar_avatar {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.navbar_user > .username {
  font-weight: 500;
  color: #ca2d5d;
}

@media screen and (max-width: 1280px) {
  .navbar_container {
    padding: 2em;
  }
  .navbar_user_links {
    top: 4.5rem;
    right: 1rem;
  }
  .navbar_inner {
    padding: 0 0px;
  }
}

@media (max-width: 1024px) {
  .navbar_links,
  .navbar_buttons,
  .navbar_user {
    display: none;
  }

  .navbar_mobile {
    display: block;
  }
}
