.courses_container {
    background-color: #F9F5FF;
}
.courses {
    gap: 32px;
    padding-top: 2em;
    padding-bottom: 2em;
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
}
.courses > .titles_container {
    gap: 12px;
    display: flex;
    flex-direction: column;
}
.titles_container > .sub_title {
    font-size: 16px;
    font-weight: 600;
    color: #6941C6;
}
.titles_container > .title {
    font-size: 2.5em;
    font-weight: 600;
    color: var(--black);
}
.courses_list {
    gap: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

@media screen and (max-width: 1280px) {
    .courses {
        padding: 2em;
    }
}
