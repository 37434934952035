.input_container {
  width: 100%;

  .quill {
    border: 1px solid #cad4e0;
    border-radius: 8px;
    background-color: transparent;

    .ql-toolbar {
      border: none;
      border-bottom: 1px solid #cad4e0;
    }
    .ql-container {
      border: none;
      border-radius: 8px;
    }
  }
}
