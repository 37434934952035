.partners {
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.gradient {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    white 0%,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0) 49.5%,
    rgba(255, 255, 255, 0) 75%,
    white 100%
  );
  z-index: 10;
}

.carouselItem {
  padding-right: 40px !important;
  margin: 0 !important;
}
