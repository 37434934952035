.home_page_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  .colored_span {
    background: linear-gradient(
      91.12deg,
      #f75083 20.38%,
      #f461e6 59.59%,
      #b665f5 97.25%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.colored_span::selection {
  background: #CA2D5D;
  color: white;
  -webkit-text-fill-color: white;
  -webkit-background-clip: text;
}

.slider {
  width: 100%;
  height: 2px;
  background: var(--Primary-Gray-100);

  .thumb {
    width: 32px;
    height: 32px;
    background: var(--Primary-ParadisePink-850);
    border-radius: 50%;
    top: -15px;
    cursor: pointer;
  }

  .track-1 {
    height: 2px;
    background: var(--Primary-ParadisePink-850);
  }
}
.header_wrapper {
  width: 100%;
  margin: 0 auto;
  /* margin-top: 140px; */
  padding-left: 8.2%;
  padding-right: 8.2%;
}
.header_container {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.header_content {
  flex: 1;
  gap: 36px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.header_text {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.header_text_colored {
  color: #8755e0;
}
.header_text > h1 {
  font-size: 2.7em;
  font-weight: 700;
  color: var(--black);
}
.header_text > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: var(--gray);
  max-width: 80%;
  text-align: justify;
}
.header_text > span {
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  color: var(--gray);
  max-width: 80%;
  text-align: justify;
}
.header_buttons {
  gap: 28px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header_image {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.image {
  width: 100%;
  object-fit: cover;
}

.header_categories {
  gap: 40px;
  display: flex;
  flex-direction: row;
}
.header_category {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header_category > span {
  font-size: 0.8em;
  font-weight: 400;
  color: var(--black);
}
.search_content {
  margin: 2em 0;
  gap: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.search_button {
  width: fit-content;
  border: none;
  cursor: pointer;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  border-radius: 8px;
  background-image: linear-gradient(to left, #d02164 0%, #7a3781 100%);
}
.search_button_disabled {
  width: fit-content;
  border: none;
  cursor: pointer;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  border-radius: 8px;
  background-image: #f8fafc;
}

@media screen and (max-width: 1100px) {
  .header_container {
    padding: 0 2em;
  }
  .header_image {
    display: none;
  }
  .header_categories {
    display: none;
  }
  .search_content {
    width: auto;
    padding: 0 2em 0 2em;
    flex-direction: column;
  }
}
.header_title {
  font-weight: 800;
  font-size: 52px;
  line-height: 64px;
  text-align: center;
}
.home_page_header_gradient {
  width: 100%;
  height: 194px;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(173, 0, 255, 0.11) 0%,
    rgba(255, 255, 255, 0) 90.5%
  );
}
@media (max-width: 640px) {
  .header_title {
    font-weight: 800;
    font-size: 22px;
    line-height: 32px;
  }
  .home_page_header_gradient {
    height: 145px;
  }
}

.custom_scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom_scrollbar::-webkit-scrollbar-track {
  background-color: #dfdfdf;
  border-radius: 4px;
}

.custom_scrollbar::-webkit-scrollbar-thumb {
  background-color: #6d6d6d;
  border-radius: 4px;
}
