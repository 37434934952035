.blur-sides {
  position: relative;
  overflow: hidden;
}

.blur-sides::before,
.blur-sides::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  z-index: 1;
}

.blur-sides::before {
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}

.blur-sides::after {
  right: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}
