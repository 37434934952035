.course_breadcrumbs {
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
}
.course_breadcrumbs_links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1280px;
    gap: 12px;
    margin: 0 auto;
    padding-top: 2em;
    color: #53545C;
    font-size: 14px;
    font-weight: 500;
    .link {
        display: flex;
        gap: 12px;
    }
    .active {
        color: #121212;
        font-weight: 600;
    }
}