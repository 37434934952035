.course_instructor {
    background-color: #FFFFFF;
}
.course_instructor_content {
    gap: 30px;
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 7.5%;
    padding-right: 7.5%;
    margin: 0 auto;
}

@media screen and (max-width: 480px) {
    .course_instructor_content  {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
.course_instructor_title {
    font-size: 0.875rem;
    font-weight: 600;
    color: #8755E0;
    text-transform: uppercase;
}
.course_instructor_image {
    border-radius: 4px;
}
.course_instructor_info {
    gap: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.instructor_infos {
    padding-top: 30px;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.instructor_info {
    display: flex;
    align-items: center;
    gap: .5rem;
}
.instructor_info_number span {
    margin-right: .25rem;
    font-weight: 600;
}
.course_instructor_details {
    gap: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.course_instructor_details > h4 {
    font-size: 2.5em;
    font-weight: 600;
    color: #000000;
}
.course_instructor_details > h5 {
    font-size: 1.25em;
    font-weight: 500;
    color: #64748B;
}
.course_instructor_details > p {
    font-size: 1em;
    font-weight: 400;
    color: #64748B;
}
.course_instructor_details > hr {
    width: 100%;
    border: 0.3px solid #CAD4E0;
}

@media screen and (max-width: 1280px) {
    .course_instructor_content {
        padding: 2em;
    }
}

@media screen and (max-width: 600px) {
    .course_instructor_info {
        flex-direction: column;
    }
}