.container {
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 7.4%;
  padding-right: 7.4%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 2em;
  }
}

@media screen and (max-width: 640px) {
  .container {
    padding: 20px 4px;
    gap: 30px;
  }
}
