.about_course {
    background-color: #FFFFFF;
}
.about_course_content {
    gap: 30px;
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 7.5%;
    margin: 0 auto;
    padding-right: 7.5%;

}
.about_course_title {
    font-size: 0.875rem;
    font-weight: 600;
    color: #8755E0;
    text-transform: uppercase;
}
.about_course_info {
    gap: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

}
.about_course_details {
    flex: 2;
    gap: 16px;
    display: flex;
    flex-direction: column;
    padding-right: 11.5%;
    
}

.about_course_details {
    flex: 2;
    gap: 16px;
    display: flex;
    flex-direction: column;
}
.about_course_details > h4 {
    font-size: 2em;
    font-weight: 600;
    color: #000000;
    margin-bottom: 0.5em;
}
.about_course_details > h5 {
    font-size: 1.25em;
    font-weight: 700;
    color: #000000;
}
.about_course_details > p {
    font-size: 1em;
    font-weight: 400;
    color: #64748B;
}

.about_course_list {
    gap: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.about_course_list_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    
}
.about_course_list_item_icon {
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #8755E0;
    object-fit: contain;

}


.about_course_list_item_text {
    gap: 8px;
    display: flex;
    flex-direction: column;
    /* max-width: 190PX;  */
    width: full;
}
.about_course_list_item_text > h6 {
    font-size: 1em;
    font-weight: 500;
    color: #000000;
}
.about_course_list_item_text > span {
    font-size: 0.875em;
    font-weight: 400;
    color: #64748B;
}

.btn_text {
    font-size: 0.75em;
    font-weight: 400;
    color: #64748B;
}

@media screen and (max-width: 1280px) {
    .about_course_content {
        padding: 2em;
    }
    .about_course_info {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .about_course_details {
        padding-bottom: 40px; 
    }
}

@media screen and (max-width: 768px) {
    .about_course_list_item_icon {
        width: 40px; 
        height: 40px; 
    }

    .about_course_list_item_icon img {
        width: 24px; 
        height: 24px;
    }

    .about_course_list_item_text > h6 {
        font-size: 0.875em; 
    }

    .about_course_list_item_text > span {
        font-size: 0.75em; 
    }

    .about_course_list_item {
        gap: 8px; 
    }
}