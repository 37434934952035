.select {
    padding: 0.8rem;
    font-size: 0.8rem;
    color: #000000;
    border-radius: 8px;
    border: 1px solid #CAD4E0;
    width: 100%;
    height: 3rem;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}