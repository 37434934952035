.blogs {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.blogs_list {
  gap: 19px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  a:hover {
    div {
      div span {
        color: var(--Primary-ParadisePink-500);
        background: var(--Primary-ParadisePink-100);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .blogs_list {
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 640px) {
  .blogs_list {
    gap: 20px;
    grid-template-columns: 1fr;
  }
}
