.input_container {
  width: 100%;
}

.upload_picture {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #cad4e0;
  border-radius: 8px;
  width: 100%;
  height: 250px;
  cursor: pointer;
  overflow: hidden;

  .default_pic {
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
.fileInput {
  display: none;
}
