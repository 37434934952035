.rate-page-container {
  padding: 3rem 15rem;

  .items {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
  }

  .rate-item {
    display: flex;
    justify-content: space-between;

    .image-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 320px;
      border-radius: 10px;
      box-shadow: 0 15px 21px rgba(0, 0, 0, 0.08);
      padding: 1.5rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      h2 {
        color: #ca2d5d;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
      }

      .domains {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        .domain {
          padding: 6px 12px;
          color: #ca2d5d;
          background-color: rgba(202, 45, 93, 0.15);
          border-radius: 20px;
        }
      }
    }

    .rating-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 50%;

      h3 {
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      textarea {
        width: 100%;
        height: 200px;
        padding: 0.8rem;
        font-size: 0.8rem;
        border: 1px solid #cad4e0;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .rate-page-container {
    padding: 3rem 10rem;
  }
}
@media screen and (max-width: 1024px) {
  .rate-page-container {
    padding: 3rem 5rem;

    .rate-item {
      flex-direction: column;
      gap: 2rem;

      .rating-container {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .rate-page-container {
    padding: 3rem 5rem;
  }
}
@media screen and (max-width: 480px) {
  .rate-page-container {
    padding: 3rem 2rem;
  }
}
