.gradient {
  background: linear-gradient(180deg, #fcf3ff 0%, rgba(255, 255, 255, 0) 100%);
  height: 645px;
  width: 100%;
  border-radius: 75px 75px 0 0;
  position: absolute;
  z-index: -1;
  left: 0;
}
.feedbacks {
  gap: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2em;
}
.feedbacks_list {
  padding: 20px;
  gap: 14px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Base for four columns */
  justify-content: center;
}

/* Target only the second row */
.second_row {
  grid-template-columns: repeat(4, 1fr); /* Maintain four equal widths */
  width: 100%;
}

/* Center the three cards in the four-column grid */
.second_row > div {
  grid-column: span 1;
}
.second_row > div:nth-child(1) {
  grid-column-start: 2; /* Start the first card from the second column */
}

@media (max-width: 1024px) {
  .feedbacks_list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 640px) {
  .feedbacks_list {
    display: none;
  }
}
