.container {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  background-color: var(--Primary-Gray-50);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 288px;
}
.course_container {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  min-width: 288px;
}
.image_container {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.image_container > .picture {
  height: 170px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}
.course_details {
  flex: 1;
  gap: 10px;
  padding: 14px;
  display: flex;
  flex-direction: column;
}
.course_titles {
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.course_category {
  font-size: 0.875rem;
  font-weight: 600;
  color: #7a3781;
}
.course_description {
  font-size: 1rem;
  font-weight: 400;
  color: #64748b;
  max-height: 38px;
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

.course_details_items {
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.course_details_block {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.rating_text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #8755e0;
  display: flex;
  flex-direction: row;
}
.rating_count_text {
  font-size: 0.875rem;
  font-weight: 400;
  color: #64748b;
}

.course_footer {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.course_provider {
  gap: 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.course_provider > span {
  font-size: 0.875rem;
  font-weight: 500;
  color: #000000;
}

.container:hover {
  background-color: var(--Primary-ParadisePink-50);
  box-shadow: 0px 0px 0px 2px var(--Primary-ParadisePink-500);

  .course_title,
  .course_price,
  .course_domain {
    color: var(--Primary-ParadisePink-500);
  }
  .course_domain {
    background: var(--Primary-ParadisePink-100);
  }
}

@media screen and (max-width: 1280px) {
  .container {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    background-color: var(--Primary-Gray-50);
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    width: 100%;
  }
  .course_container {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    min-width: 100%;
  }
}

@media (max-width: 640px) {
  .container {
    width: 100%;
  }
  .course_container {
    flex-direction: row;
    width: 100%;
  }
  .image_container {
    padding: 6px;
    height: 100%;
    width: 163px;
    border: none;
  }
  .image_container > .picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  .course_details {
    gap: 5px;
    padding: 5px;
  }
}
