.date-range-picker {
  display: flex;
  align-items: center;
  border: 1px solid #cad4e0;
  border-radius: 8px;
  padding: 5px;

  .custom-input {
    width: 120px;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
  }
  .custom-input:focus {
    outline: none;
  }
  .arrow {
    margin: 0 10px;
    font-size: 18px;
    color: #555;
  }
  img {
    width: 20px;
    margin-left: auto;
    margin-right: 5px;
    color: #888;
  }
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}