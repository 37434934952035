.container {
  gap: 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2em;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 2em 0 0 0;
  }
}

@media screen and (max-width: 640px) {
  .container {
    padding: 0;
  }
}
