.popup_container {
  .ReactModal__Content--after-open {
    width: 473px;
  }

  @media (max-width: 473px) {
    .ReactModal__Content--after-open {
      width: 100%;
    }
  }
}
