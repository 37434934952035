.course_stories {
  background-color: #f9f5ff;
}
.course_stories_content {
  gap: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 8.2%;
  padding-right: 8.2%;
  margin: 0 auto;
  padding-top: 3em;
  padding-bottom: 3em;
}
.course_stories_info {
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.course_stories_subtitle {
  font-size: 0.875em;
  font-weight: 600;
  color: #8755e0;
  text-transform: uppercase;
}
.course_stories_title {
  max-width: 600px;
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  color: #000000;
}
.course_stories_description {
  font-size: 1em;
  font-weight: 400;
  color: #64748b;
  text-align: center;
}

.course_stories_author {
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.course_stories_author_image {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.course_stories_author_name {
  font-size: 1em;
  font-weight: 600;
  color: #000000;
  text-align: center;
}
.course_stories_author_position {
  font-size: 0.875em;
  font-weight: 400;
  color: #64748b;
  text-align: center;
}

@media screen and (max-width: 1280px) {
  .course_stories_content {
    padding: 2em;
  }
}
