.loader {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #E83C70; 
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .custom-carousel-item {
  margin: 0 15px !important;
  width: 322px !important;
  height: 120px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 24px;
  padding: 15px;
  background: white;
  border: 1px solid #D1D5DB; 
  transition: all 0.3s ease-in-out;
  position: relative;
} */

/* .custom-carousel-item:hover {
  background: #FFFAFB;
  border: 2px solid #E83C70; 
}

.custom-carousel-item:hover .date-text {
  color: #E83C70; 
} */

.discount-badge {
  background: #E83C70; 
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  text-align: center;
  white-space: nowrap;
  width: 73px;
  height: 24px;
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Header */
.header {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

/* Carousel Container */
.carouselContainer {
  width: 100%;
  max-width: 1200px;
  height: 130px;
  align-items: center;
  justify-content: center;

}

/* Carousel Items */
.carouselItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 24px;
  padding: 15px;
  background: white;
  border: 1px solid #D1D5DB; 
  transition: all 0.3s ease-in-out;
  position: relative;
  height: 110px;
  max-width: 322px;
  margin: 10 15px !important;
}





.carouselItem:hover {
  background: #FFFAFB;
  border: 2px solid #E83C70; 
}

.carouselItem:hover .date-text {
  color: #E83C70; 
}

/* Session Date */
.sessionDate {
  font-size: 16px;
  font-weight: bold;
  color: #1b0209;
  text-align: center;
}

/* No Sessions Text */
.noSessions {
  text-align: center;
  color: gray;
  font-size: 14px;
}

/* Loader */
.loader {
  width: 40px;
  height: 40px;
  border: 5px solid #ccc;
  border-top-color: #370513;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Spinner Animation */
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .carouselContainer {
    max-width: 800px;
    height: 110px;
  }

  .carouselItem {
    max-width: 310px;
    height: 100px;
  }
}

@media (max-width: 640px) {
  .carouselContainer {
    max-width: 370px;
    height: 87px;
    justify-content:baseline;
    padding-left: 10px;
    align-items: center;
    text-align: center;
  }

  .carouselItem {
    max-width: 266px;
    height: 87px;
  }

  .sessionDate {
    font-size: 16px;
  }
  
  
}


.react-multiple-carousel__arrow--left {
  left: 1px !important; 
  position:absolute;
  top: 52%;
  transform: translateY(-50%);
  background-color: #FDFCFF;
  z-index: 1;
}

.react-multiple-carousel__arrow--right {
  right: 10px !important; 
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  background-color: #FDFCFF;
  z-index: 1;
}

.react-multiple-carousel__arrow::before {
  color: #1D0839  !important;
  font-size: 18px !important; 
}

/* Details Section */
@media (max-width: 1024px) { /* Tablet */
  .details-container {
    max-width: 800px;
    height: 358px;
    flex-direction: row;
    width: 100%;
    
  }

  .big-image {
    max-width: 376px;
    max-height: 238px;
  }

  .small-images {
    display: none;
  }

  .btn-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    max-width: 85%;
  }
  .details-btn {
    width: 100%;
    height: 40px;

  }
  
}

@media (max-width: 1024px) { /* Tablet */
  .location-text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%; 
    line-clamp: 1;
  }
}


@media (max-width: 640px) { /* Phone */
  .details-container {
    max-width: 350px;
    height: 520px;
    flex-direction: column;
  }

  .big-image {
    /* max-width: 200px;
    max-height: 78px; */
    border-radius: 12px !important;
  }

  .small-images {
    display: none;
  }

  .details-btn {
    width: 100%;
    height: 40px;
  }


  .btn-container {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 2px;

  }

  .display-phone {
    align-items: center;
  }

  
  
}
