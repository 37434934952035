.blog {
  gap: 24px;
  display: flex;
  flex-direction: row;
}
.last_blog {
  gap: 24px;
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}
.blog_picture {
  flex: 1;
}
.blog_picture > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.blog_details {
  flex: 1;
  background: #ffffff;
}
.blog_title {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  /* margin-bottom: 6px; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog_description {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 900px) {
  .blog {
    flex-direction: column;
  }
  .last_blog {
    grid-column-start: 1;
    grid-row-start: 3;
    grid-row-end: 4;
  }
}
