.filter_section {
    width: 250px; /* Adjust width as needed */
    padding: 20px;
    border-right: 1px solid #CAD4E0; /* Adds a line between the filters and the main content */
}

.filter_section h3 {
    margin-bottom: 20px;
}

.filter_group {
    margin-bottom: 20px;
}

.filter_group h4 {
    margin-bottom: 10px;
}

.filter_options label {
    display: block;
    margin-bottom: 5px;
}

.filter_options input[type="checkbox"] {
    margin-right: 10px;
    background-color: var(--purple);
}
.filter_options input[type="range"] {
    width: 100%;
}

.price_range {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.search_button {
    width: 100%;
    border: none;
    cursor: pointer;
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: 500;
    color: #FFFFFF;
    border-radius: 8px;
    background-image: linear-gradient(to left, #d02164 0%, #7a3781 100%);
}
.search_button_disabled {
    width: 100%;
    border: none;
    cursor: pointer;
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: 500;
    color: #000000;
    border-radius: 8px;
    background-image: #F8FAFC;
}

@media screen and (max-width: 850px) {
    .filter_section {
        width: 100%;
        border-right: none;
    }
}