.course_header {
  background: linear-gradient(
    to right,
    #fcfafc 0%,
    #f8e8f2de 80%,
    #fcfafc 100%
  );
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 480px) {
  .course_header  {
    padding-left: 0;
    padding-right: 0;
  }
}
.course_header_content {
  gap: 2em;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 7.5%;
  padding-right: 7.5%;
}
.course_header_description {
  gap: 16px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  .date_and_location {
    gap: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.tag {
  padding: 8px;
  font-size: 0.6em;
  font-weight: 500;
  color: #ffffff;
  background-color: #8755e0;
  border-radius: 8px;
}
.course_header_description > h1 {
  font-size: 2em;
  font-weight: 600;
  color: #000000;
}
.course_header_description > p {
  font-size: 1em;
  font-weight: 400;
  color: #000000;
  text-align: justify;
}
.course_header_description > .priceText {
  font-size: 2em;
  font-weight: 600;
  color: #8755e0;
}
.course_header_details_container {
  gap: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.course_header_details,
.course_schedule_details {
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.course_header_details_item {
  gap: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.course_header_details > span {
  font-size: 0.875em;
  font-weight: 400;
  color: #000000;
}
.course_header_details .creatorText {
  font-size: 1em;
  font-weight: 600;
}
.course_schedule_details {
  cursor: pointer;

  span {
    color: #ca2d5d;
  }
}
.cta_container {
  gap: 16px;
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
}

.course_header_image {
  width: 538px;
  height: 330px;
  object-fit: cover;
  border-radius: 8px;
}

@media screen and (max-width: 1280px) {
  .course_header_content {
    padding-left: 2em;
    padding-right: 2em;
  }
  .course_header {
    background: linear-gradient(
      to right,
      #fcfafc 0%,
      #f8e8f2de 80%,
      #fcfafc 100%
    );
  }
  .course_background {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .course_header_content {
    flex-direction: column;
  }
  .course_header_content {
    .course_header_image {
      width: 100%;
      height: 100%;
      order: -1;
      object-fit: scale-down;

    }
    .course_header_description {
      .course_schedule_details {
        flex-direction: row-reverse;
        text-align: end;
        .info_icon {
          display: none;
        }
      }
      .date_and_location {
        flex-direction: row;
        justify-content: space-between;
        order: -3;
      }
      h1 {
        order: -2;
      }
      p {
        order: -1;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .cta_container {
    flex-direction: column;
  }
}


@media screen and (max-width: 480px) {
  .course_header_details
  {
    span {
      font-size: 0.75em;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}

@media screen and (max-width: 480px) {


  .date_and_location, 
  .course_schedule_details, 
  .course_header_details {
    img {
      width: 16px !important; 
      height: 16px !important; 
    }

    span {
      font-size: 0.85em; 
    }
  }

  .date_and_location {
    span {
      font-size: 0.85em; 
    }
  }

  .course_schedule_details {
    span {
      font-size: 0.85em; 
    }
  }

  .course_header_details {
    span {
      font-size: 0.85em; 
    }
  }
}

