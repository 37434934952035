.payment_container {
    gap: 32px;
    padding-top: 2em;
    padding-bottom: 2em;
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    text-align: center;
    flex-direction: column;
}
.payment_content {
    gap: 50px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

@media screen and (max-width: 1280px) {
    .payment_container {
        padding-left: 2em;
        padding-right: 2em;
    }
}

@media screen and (max-width: 1000px) {
    .payment_content {
        flex-direction: column;
    }
}