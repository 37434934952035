.payment_details {
    gap: 20px;
    width: 370px;
    display: flex;
    flex-direction: column;
}
.course_details {
    gap: 10px;
    display: flex;
    padding: 10px;
    flex-direction: row;
    border: 1px solid #CAD4E0;
    border-radius: 8px;
}
.course_details > img {
    width: 50%;
    object-fit: cover;
    border-radius: 1px;
}
.course_info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.course_info_header {
    gap: 7px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.course_info_header > .course_category {
    font-size: 0.9em;
    font-weight: 400;
    color: #8755E0;
    text-align: left;
}
.course_info_header > .course_title {
    font-size: 1em;
    font-weight: 500;
    color: #000000;
    text-align: left;
    max-height: 40px;
    display: block;/* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
}
.course_info_header > p {
    font-size: 0.7em;
    font-weight: 400;
    color: #64748B;
    text-align: left;
    max-height: 28px;
    display: block;/* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
}
.course_footer {
    display: flex;
    margin-top: 5px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.course_instructor {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
}
.course_instructor_image {
    border-radius: 10px;
}
.course_instructor_name {
    font-size: 0.7em;
    font-weight: 500;
    color: #000000;
}
.course_price {
    font-size: 0.9em;
    font-weight: 500;
    color: #8755E0;
}

.payment_billing {
    gap: 10px;
    display: flex;
    padding: 15px;
    flex-direction: column;
    border: 1px solid #CAD4E0;
    border-radius: 8px;
}
.payment_billing > h4 {
    font-size: 1em;
    font-weight: 600;
    color: #000000;
    text-align: left;
}
.payment_billing_details {
    gap: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;
}
.payment_billing_details_item {
    display: flex;
    font-size: 0.8em;
    font-weight: 300;
    flex-direction: row;
    align-items: center;
    color: #000000;
    justify-content: space-between;
}
.promo_code_text {
    font-size: 0.8em;
    font-weight: 300;
    color: #CA2D5D;
    text-align: left;
}
.promo_code {
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.payment_billing > hr  {
    margin: 10px 0;
    border: 0.5px solid #CAD4E0;
}
.payment_billing_details_total {
    font-weight: 600;
}
.checkbox_container {
    gap: 5px;
    display: flex;
    font-size: 0.8em;
    color: #000000;
    margin-top: 1.5em;
    margin-bottom: 1em;
    align-items: flex-start;
    text-align: left;
}
.privacy_link {
    color: #8755E0;
}

.and_symbol
{
    color: #000000;
    margin: 0 1px;
}
@media screen and (max-width: 1000px) {
    .payment_details {
        width: 100%;
    }
}