.course_breadcrumbs {
    background: linear-gradient(to right, #fcfafc 0%, #f8e8f2de 80%, #fcfafc 100%);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
   
}
.course_breadcrumbs_links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  
    gap: .5rem;
    margin: 0 auto;
    padding-top: 2em;
    color: #72849a;
    padding-left: 7.3%;
    padding-right: 7.3%;

  .link {
    display: flex;
    gap: 0.5rem;
  }
  .active {
    color: #1a1a1a;
  }
}

@media screen and (max-width: 1280px) {
  .course_breadcrumbs_links {
    padding-left: 2em;
    padding-right: 2em;
  }
  .course_breadcrumbs {
    background: linear-gradient(
      to right,
      #fcfafc 0%,
      #f8e8f2de 80%,
      #fcfafc 100%
    );
  }
  .course_background {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .course_breadcrumbs {
    padding-left: 0;
    padding-right: 0;
  }

  .course_breadcrumbs_links {
    padding-left: 0;
    padding-right: 0;
  }
}

