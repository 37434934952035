.playlist_container {
  background-color: #ffffff;
}
.playlist {
  gap: 32px;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 8.2%;
  padding-right: 8.2%;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.playlist_list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 560px;
}
.playlist_item_image {
  object-fit: cover;
  border-radius: 8px;
}
.playlist_item {
  gap: 8px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
}
.playlist_item_selected {
  border-left: 4px solid #ca2d5d;
}
.playlist_item_info {
  flex: 1;
  gap: 4px;
  display: flex;
  flex-direction: column;
}
.playlist_item_info > h4 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  text-align: justify;
}
.playlist_item_info > p {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  color: #64748b;
  text-align: justify;
  height: 2.5em;
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

.playlist_video {
  flex: 2;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.react_player {
  overflow: hidden;
  border-radius: 8px;
}

@media screen and (max-width: 1280px) {
  .playlist {
    padding: 2em;
    flex-direction: column;
  }
  .playlist_list {
    flex-direction: row;
  }
  .playlist_item_selected {
    border-left: none;
    border-bottom: 4px solid var(--pink);
  }
  .playlist_item_info {
    display: none;
  }
}
