.review_card {
    flex: 0 0 calc(33.33% - 20px);
  }
  
  @media screen and (max-width: 1024px) {
    .review_card {
      flex: 0 0 calc(50% - 20px);
    }
  }
  
  @media screen and (max-width: 640px) {
    .review_card {
      flex: 0 0 calc(100% - 20px);
    }
  }
  