.course_goals {
    background-color: #FFFFFF;
}
.course_goals_content {
    gap: 60px;
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 7.5%;
    padding-right: 7.5%;
    margin: 0 auto;
}
.course_goals_item {
    gap: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    align-items: flex-start;
    margin-bottom: 40px;
    justify-content: space-between;
    flex-wrap: wrap;

}
.course_goals_details {
    gap: 32px;
    max-width: 583px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

}

.course_goals img {
    border-radius: 8px;
    /* object-fit:scale-down; */
    object-position: center;
    max-width: 100%; 
    width: 519px;  
    height:  360px;
    max-height: 100%;
}

@media screen and (max-width: 768px) {
    .course_goals img {
      width: 100%; 
      height: auto; 
    }
  }
  

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
.course_goals_details > h3 {
    font-size: 2rem;
    font-weight: 700;
    color: #000000;
}
/* .course_goals_details > p {
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
} */

@media screen and (max-width: 1280px) {
    .course_goals_content {
        padding: 2em;
    }
}

@media screen and (max-width: 900px) {
    .course_goals_item {
        flex-direction: column;
    }
    .course_goals_details {
        width: 100%;
        align-items: center;
    }
}

/* Adjust for mobile screens and convert Z-shaped Layout for mobile into vertical Layout */
@media screen and (max-width: 768px) {
    .course_goals_item {
      flex-direction: column; 
      align-items: center; 
      gap: 20px; 
    }
  
    .course_goals img {
      order: -1; 
      width: 100%; 
      height: auto; 
    }
  
    .course_goals_details {
      text-align: center; 
      width: 100%; 
      gap: 16px; 
    }
  
    .course_goals_details > p {
      font-size: 1rem; 
    }
  
    .course_goals_details > div {
      width: 100%;
    }
  
    .course_goals_details button {
      width: auto; 
    }
  }
  