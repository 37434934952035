.container {
  gap: 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2em 10%;
  text-align: center;

  button {
    transition: all 0.2s ease;
  }
  .primary_button {
    &:hover {
      box-shadow: inset 0 0 0 2px var(--Primary-ParadisePink-500);
      background: transparent;
      color: var(--Primary-ParadisePink-500);
    }
    &:active {
      background: var(--Primary-ParadisePink-100);
      box-shadow: inset 0 0 0 3px var(--Primary-ParadisePink-600);
      color: var(--Primary-ParadisePink-600);
    }
  }
  .secondary_button {
    &:hover {
      background: #8755e0;
      color: white;
    }
    &:active {
      background: var(--Primary-ParadisePink-300);
    }
  }
}
.background {
  background-color: #fdfcff;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
}
.colored_span {
  background: linear-gradient(
    272.63deg,
    #8755e0 7.46%,
    #ab7aec 35.05%,
    #e1b3fe 77.29%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.colored_span::selection {
  background: #ca2d5d;
  color: white;
  -webkit-text-fill-color: white;
  -webkit-background-clip: text;
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 2em 0;
  }
}
